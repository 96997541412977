<!-- 累充活动 -->
<template>
  <div class="relative z-10 flex justify-center w-full">
    <div
      v-imloading="loading"
      :class="isMobile ? 'gears_wrap_mobile w-full' : 'gears_wrap_desk w-full'"
    >
      <div
        :class="['activity_info flex items-center justify-between']"
      >
        <div class="flex items-center">
          <img v-if="gearsData.length" class="w-[50px] mr-3" :src="activityData.virtual_currency_icon" alt /><span>{{ $t('siteBuild.activity.my') }}：{{(activityData.virtual_currency_num || 0)}}</span>
        </div>
        <span class="flex items-center"><i class="el-icon-timer text-[24px]" />：{{sTime}}</span>
      </div>
      <i-progress :class="isMobile ? 'mobile' : ''" :is-mobile="isMobile" :percent="editing ? 50 : progressPercent" :info-text="infoText" :model="model" :data="gearsData" />
      <div class="w-full flex">
        <div class="gears-content">
          <div v-for="(item, index) in gearsData" :key="`gears_${index}`" class="py-[10px]">
            <bg-style
              :class="['gears-wrap-list w-full rounded overflow-hidden']"
              :bg="model.giftBackground"
            >
              <div class="gift_info__node relative mb-2 w-full">{{ $t('siteBuild.activity.addUp') }} {{ item.virtual_currency_val }}</div>
              <div class="gift_info">
                <el-scrollbar class="w-full">
                  <div class="gift_list flex w-full">
                    <bg-style
                      v-for="(gift, idx) in item.gift_list"
                      :key="`gift_${index}_${idx}`"
                      class="gift_list_row_item flex relative flex-shrink-0"
                      :bg="model.giftItemBackground"
                    >
                      <img :src="gift.gift_goods_logo" class="w-[60px] h-[60px] flex-shrink-0 relative" alt />
                      <div class="item-num relative" :style="numberColorStyle">{{gift.num}}</div>
                      <bg-style
                        v-if="item.level_status === 3 && model.receivedMaskShow"
                        class="absolute top-0 left-0 bottom-0 right-0 z-30"
                        :bg="model.receivedMask"
                      ></bg-style>
                    </bg-style>
                  </div>
                </el-scrollbar>
                <div class="flex items-center justify-center item-gift pl-10">
                  <!-- 未达标 -->
                  <bg-style
                    v-if="item.level_status === 1"
                    :bg="model.nqBtnBackground"
                    class="sum_btn"
                  >
                    <span class="relative whitespace-nowrap" :style="{ color: model.nqBtnColorShow ? model.nqBtnColor : 'unset' }">{{$t('siteBuild.activity.notQualified')}}</span>
                  </bg-style>
                  <!-- 领取 -->
                  <bg-style
                    v-if="item.level_status === 2"
                    :bg="model.getBtnBackground"
                    class="sum_btn cursor-pointer"
                    @click.native="handlePick(item)"
                  >
                    <span class="relative whitespace-nowrap" :style="{ color: model.getBtnColorShow ? model.getBtnColor : 'unset' }">{{$t('siteBuild.activity.get')}}</span>
                  </bg-style>
                  <!-- 已领取 -->
                  <bg-style
                    v-if="item.level_status === 3"
                    :bg="model.receivedBtnBackground"
                    class="sum_btn"
                  >
                    <span class="whitespace-nowrap relative" :style="getTextColor(model.receivedBtnColor, model.receivedBtnColorShow)">{{$t('siteBuild.activity.haveGet')}}</span>
                  </bg-style>
                </div>
              </div>
            </bg-style>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chunk from 'lodash.chunk'
import isNil from 'lodash.isnil'
import { mapState } from 'vuex'
import moment from 'moment'
import IProgress from "./progress.vue";
import tools, {formatZoneTime, getTextColor} from '~/utils'
import Bus from '~/site/model/bus'
import { isBuildWebSite, isPre, GET_SITE_ID } from '~/config'

let isPreviewMode = !isBuildWebSite
const SiteID = GET_SITE_ID()

if (isBuildWebSite && isPre && SiteID.startsWith('56') && SiteID.length === 2) {
  isPreviewMode = true
}

export default {
  name: 'SumComponents',
  components: {
    IProgress
  },
  props: {
    model: {
      type: Object,
      default: () => { }
    },
    isMobile: Boolean,
    isHaveGiftRowBg: Boolean,
    editing: Boolean
  },
  data() {
    return {
      loading: false,
      activityData: {},
      gearsData: [],
      sTime: '00:00:00',
      progressPercent: 0,
      totalNumber: 0
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
    // 数字颜色
    numberColorStyle() {
      const { numberColorShow, numberColor } = this.model
      return numberColorShow ? { color: numberColor } : { color: 'unset' }
    },
    infoText() {
      return `${this.activityData.virtual_currency_num}/${this.totalNumber}`
    }
  },
  created() {
    this.getActivityData()
  },
  mounted() {
    Bus.$on('reloadActivity', () => {
      this.getActivityData()
    })
  },
  destroyed() {
    Bus.$off('reloadActivity')
  },
  methods: {
    getTextColor,
    // 领取
    handlePick(item) {
      if (this.editing) return
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        // 只通过 player_id登录的系统（游客模式）
        this.$store.commit('user/SET_SITE_BIND_USER', true)
      } else {
        this.loading = true
        const device = this.isMobile ? 'mobile' : 'desktop'
        const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
        const return_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        const gift_items = item.gift_list.map(i => {
          i.logo = i.gift_goods_logo
          return i
        })

        this.$api.activity.pickActivityItems({
          project_id: this.projectId || this.userInfo.project_id || '',
          merchant_id: this.userInfo.merchant_id || '',
          gift_items,
          activity_id: this.activityData.biz_id,
          player_id: (this.userInfo && this.userInfo.uid || '').trim(),
          level_virtual_currency_val: item.virtual_currency_val,
          from_preview_mode: isPreviewMode,
          settings: {
            device,
            language: lang,
            show_order: false,
            return_url
          }
        })
          .then(() => {
            this.getActivityData()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    getActivityData() {
      this.loading = true
      // const now_stem = tools.getNowUtcStamp()
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      this.$api.activity.getActivityItems({
        now_time: now_stem,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || ''
      }, { editing: this.editing })
        .then(res => {
          this.activityData = res
          this.gearsData = res.activity_level_hit_list || []
          this.totalNumber = res.activity_level_hit_list[res.activity_level_hit_list.length - 1]?.virtual_currency_val || 0
          this.progressPercent = ((res.virtual_currency_num / this.totalNumber) * 100).toFixed(2)
          this.countTime()
        })
        .catch(() => {
          this.activityData = {}
          this.gearsData = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    countTime() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.activityData.activity_stop_time * 1000).value
      const leftTime = end - now;
      // console.log(now, str, end, leftTime)
      // 定义变量 d,h,m,s保存倒计时的时间
      let d = 0; let h = 0; let m = 0; let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.sTime = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`

      // 递归每秒调用countTime方法，显示动态时间效果
      if (leftTime > 0) setTimeout(this.countTime, 1000);
    },
    // 分组
    chunkGiftData(arr) {
      return chunk(arr, this.isMobile ? 4 : 9)
    }
  },
}
</script>
<style lang="less">
.gears_wrap_desk {
  .gears-content {
    width: 100%;
  }
  .activity_info {
    font-family: PingFang SC;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .gift_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .gift_list {
      column-gap: 24px;
      .gift_list_row_item {
        width: 86px;
        height: 86px;
        padding-top: 5px;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        .item-num {
          //min-height: 24px;
          //font-size: 14px;
          //line-height: 22px;
          //text-align: center;
          //position: absolute;
          //bottom: 5px;
          //right: 15px;
          //font-weight: bold;
        }
      }
    }

    .sum_btn {
      min-width: 80px;
      height: 36px;
      border-radius: 2px;
      padding: 0 12px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .gears-wrap-list {
    padding: 24px;
  }
}

.gears_wrap_mobile {
  .gears-content {
    width: 100%;
  }
  .activity_info {
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .gears-wrap-list {
    padding: 12px;
  }
  .gift_info {
    display: flex;
    flex-direction: column;
    .gift_list {
      gap: 8px;
      .gift_list_row_item {
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        padding-top: 5px;
      }
    }

    .item-gift {
      margin-top: 16px;
      padding-left: 0 !important;
    }

    .sum_btn {
      min-width: 72px;
      height: 32px;
      border-radius: 2px;
      padding: 0 8px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
