<template>
  <bg-style
    :id="`store-good-id-${good.id}`"
    :class="['good', 'relative', 'p-0', 'justify-between', 'flex-col', 'layout-col', {
      skeleton: isTmp
    }]"
    :bg="model.goodsBackground"
    :radius="model.goodsBorderRadius"
  >
    <!-- 角标 -->
    <im-image v-if="isHasCorner" :src="good.corner" class="absolute top-0 right-0 good-logo corner-logo" is-lazy :scroll-container="lazyScrollContainer" />
    <div class="store-goods__info">
      <div class="flex flex-col info-wrapper">
        <div class="popover-wrapper">
          <!-- 商品名称 -->
          <el-popover v-if="goodName" append-to-body placement="bottom" width="200" trigger="click">
            <div>{{ goodName }}</div>
            <div
              slot="reference"
              :class="['name', {
              'has-corner': isHasCorner
            }, 'relative']"
              :style="{
              'color': model.goodsTitleColorShow ? model.goodsTitleColor : 'unset',
            }"
            >
              {{ goodName }}
            </div>
          </el-popover>
          <div
            v-else
            class="name"
            :style="{
            'color': model.goodsTitleColorShow
              ? model.goodsTitleColor
              : 'unset',
          }"
          >
            {{ goodName }}
          </div>
        </div>
      </div>
      <div v-if="stopCountdownTimer && !isShowCountdown" class="good-time text-14 font-bold flex items-center relative">
        <i class="el-icon-timer" />
        <span class="ml-1">{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
      </div>
      <!-- 商品图片 -->
      <div
        class="flex justify-center icon"
        @click="openDescriptionPopover"
        @mouseleave="cardMouseLeave"
      >
        <im-icon v-if="isTmp" class="text-[36px] tmp-image" icon="icon-a-emptyproduct" />
        <im-image v-else :src="good.icon" class="relative good-logo" is-lazy :scroll-container="lazyScrollContainer" />
      </div>
      <el-scrollbar v-if="good.gift_list && good.gift_list.length" class="store-good__gift my-2">
        <div v-for="(item, index) in good.gift_list" :key="index" class="gift-item">
          <img :src="item.gift_goods_logo" alt="">
          <div class="gift-item__num" :style="titleColor">*{{ item.num }}</div>
        </div>
      </el-scrollbar>
      <el-popover
        v-if="goodDescription"
        v-model="descPopoverVisible"
        append-to-body
        placement="top"
        width="200"
        trigger="focus"
      >
        <div class="break-normal text-left">{{ goodDescription }}</div>
        <div
          slot="reference"
          class="descriptions relative"
          :style="{
          'color': model.goodsDescriptionColorShow
            ? model.goodsDescriptionColor
            : 'unset',
        }"
          @click="openDescriptionPopover"
          @mouseleave="cardMouseLeave"
        >
          {{ goodDescription }}
        </div>
      </el-popover>
      <div
        v-else
        class="descriptions relative"
        :style="{
        'color': model.goodsDescriptionColorShow
          ? model.goodsDescriptionColor
          : 'unset',
      }"
      >
        {{ goodDescription }}
      </div>
      <div v-if="good.is_purchase_limit" class="flex items-center px-[2rem] mt-2">
        <div class="good-limit" :style="{ border: `1px solid ${model.goodsTitleColor || 'currentColor'}`, color: model.goodsTitleColor || 'currentColor' }">Limit {{ good.has_purchase_num }}/{{ good.limit_purchase_num }}</div>
      </div>
    </div>

    <!-- 商品价格展示 -->
    <div
      :class="['relative', 'flex', 'flex-col', 'flex-wrap', 'items-start', 'justify-between', 'cursor-pointer', 'bottom-wrapper', {
        'cursor-not-allowed': isSaleOut
      }]"

    >
      <div class="w-full h-full buy-box flex items-center justify-center" :class="{ 'is-disabled': isSaleOut }">
        <div v-if="isShowCountdown" class="countdown-block h-full flex items-center justify-center text-white">
          <i class="el-icon-timer text-[24px] mr-2"  />
          <span>{{ countdownDays }}d:{{ countdownHours }}:{{ countdownMinutes }}:{{ countdownSeconds }}</span>
        </div>
        <shop-cart v-if="model.shopCartEnabled" class="w-[40%] h-full flex-shrink-0" :good="good" :disabled="editing" :model="model" />
        <bg-style
          class="flex items-center justify-center w-full h-full text-center price-info relative"
          :bg="model.buyBackground"
          @click.native="buy(good)"
        >
          <!-- 可购买时间 -->
          <div v-if="!isShowCountdown" class="relative">
            <div v-if="isTmp" class="price text-24">$XX.XX</div>
            <template v-else>
              <!-- 划线价格 -->
              <div v-if="isShowOriginalPrice" class="line-through price-original">
                {{ originalPriceText }}
              </div>
              <!-- 真实价格 -->
              <div class="price" :style="getColorStyle(model.goodsPriceColor, model.goodsPriceColorShow)">{{ realPriceText }}</div>
            </template>
          </div>
        </bg-style>
      </div>
    </div>
  </bg-style>
</template>

<script>
import goodMixins from './goodMixins'
import shopCart from './shopCart.vue'

export default {
  name: 'BackgroundGood',
  components: {
    shopCart
  },
  mixins: [goodMixins],
  mounted() {
    // console.log(this.good)
  }
}
</script>

<style lang="less" scoped>
.position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-style {
  @apply font-semibold w-full text-20 break-all text-ellipsis overflow-hidden mt-5 px-6 text-center;
  line-height: 24px;
  height: 48px;
  // color: #292422;
  /* 这里是超出几行省略 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

// 正常有商品数据的样式
.good {
  @apply box-border flex;
  padding: 28px 19px;
  width: 278px;
  height: 420px;
  margin: 14px 12px;
  background-size: 278px 365px!important;
  &-time {
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 10;
  }
  .good-limit {
    border: 1px solid unset;
    border-radius: 2px;
    padding: 0 4px;
  }

   .store-good__gift {
    width: 80%;
    margin: 0 auto;
    /deep/ .el-scrollbar__view {
      display: flex;
      align-items: center;
    }
    .gift-item {
      flex-shrink: 0;
      position: relative;
      margin-right: 10px;
      display: flex;
      align-items: flex-end;
      img {
        width: 30px;
        height: 30px;
      }
      &__num {
        font-weight: bold;
      }
    }
  }

  .corner-logo {
    width: 84px;
    height: 84px;
    z-index: 1;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  .icon {
    @apply w-full;

    .good-logo {
      width: 180px;
      height: 180px;

      :deep(img) {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .tmp-image {
      height: 180px;
    }

    .sale-out__wrapper {
      @apply relative w-full;
      height: 180px;

      .sale-out__img {
        .position-center;
      }

      .sale-out__text {
        .position-center;
        transform: translate(-60%, -50%) rotate(-15deg);
        color: white;
        font-size: 18px;
      }
    }
  }

  .name {
    .text-style;

    // &.has-corner {
    //   padding-right: 78px;
    // }
  }

  .descriptions {
    .text-style;
    @apply font-extralight;
    font-size: 14px;
    height: auto;
    line-height: unset;
    margin-top: unset;
  }
  .buy-box {
    position: relative;
    .countdown-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      font-size: 20px;
      z-index: 10;
    }
    &.is-disabled {
      &:after {
        content: "Sold Out";
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        font-size: 18px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .bottom-wrapper {
    height: 60px;
    background-size: 278px 95px!important;

    .price-info {
      @apply font-medium text-14;

      .price-original {
        @apply text-16;
        margin-bottom: 5px;
        line-height: 16px;
      }

      .price {
        @apply text-24;
        line-height: 24px;
      }

      .timer-style {
        @apply flex justify-center items-center left-0 right-0 text-28 cursor-not-allowed;
      }
    }

    .right {
      .car {
        @apply cursor-pointer;
      }

      .buy-btn {
        .btn {
          @apply text-12 h-7 border-0 flex items-center justify-center p-0;
          min-width: 80px;
          background-color: var(--button-background-color);
          background-image: var(--button-background-image);
          opacity: var(--button-background-opacity);
          border-radius: var(--button-border-radius-px);
          color: var(--button-text-color);
        }

        .btn.is-disabled {
          background: #b3b3b3 !important;
          color: #fff !important;
        }
      }
    }
  }

  // 无商品数据情况下的样式
  &.skeleton {
    .icon {
      .img {
        @apply w-full rounded;
        height: 180px;
        background: rgba(255, 255, 255, 0.12);

        .im-icon {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .bottom-wrapper {
      .right {
        .car {
          @apply cursor-pointer;
        }
      }
    }
  }
}

// 750px 样式
.site-device-750 {
  .good {
    background-size: 164px 215px !important;
    &-time {
      top: -20px;
    }
    .store-good__gift {
      overflow-x: auto;
      width: 80%;
      margin: 0 auto;
      gap: 10px;
      .gift-item {
        flex-shrink: 0;
        position: relative;
        margin-right: 5px;
        display: flex;
        align-items: flex-end;
        img {
          width: 20px;
          height: 20px;
        }
        &__num {
          font-weight: bold;
        }
      }
    }

    // 商品两列布局方式
    &.layout-col {
      @apply flex box-border w-1/2 flex-col;
      height: 290px;
      width: 164px;
      margin: 14px 5px;

      .corner-logo {
        width: 48px;
        height: 48px;
      }

      .sale-out__text {
        @apply text-16;
      }

      .name {
        @apply text-16 mt-3 pl-4 mb-3;
        height: auto;
        line-height: 17px;
      }

      .icon {

        .tmp-image {
          height: 102px;
        }

        .sale-out__wrapper {
          height: 102px;
        }

        .good-logo {
          width: 102px;
          height: 102px;
        }
      }

      .descriptions {
        @apply text-14 mt-2;
        line-height: 17px;
      }

      .bottom-wrapper {
        height: 50px;
        background-size: 164px 55px!important;

        .price-info {

          .price-original {
            line-height: 17px;
            margin-bottom: 3px;
            @apply text-12;
          }

          .price {
            line-height: 22px;
            @apply text-16;
          }

          .timer-style {
            @apply text-18;
          }
        }
      }
    }
  }
}
</style>
