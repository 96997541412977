<template>
  <div v-if="model.enable" class="module-navigation" :style="{ '--offset-left': offsetLeft }" :class="{ 'is-edit': editing, 'is-mobile': device === DeviceEnum.MOBILE }">
    <el-popover
        v-model="visible"
        popper-class="module-navigation__popover"
        append-to-body
        :visible-arrow="false"
        placement="top"
        trigger="manual"
    >
      <bg-style :bg="model.background" class="module-navigation__content">
        <div class="menu-list relative">
          <div v-for="(item, index) in menuList" :key="item.id" class="item" :class="{ 'active': activeIndex === index }" @click.stop="handleToModule(item, index)">
            <im-icon class="text-18" :icon="item.icon" />
            <span class="ml-3 item-title">{{ item.title }}</span>
          </div>
          <div class="item justify-center" @click.stop="handleToTop">
            <i class="el-icon-upload2 text-18" />
          </div>
        </div>
      </bg-style>
      <div slot="reference" class="module-navigation__btn" @click="handleShowMenu">
        <i class="text-18" :class="visible ? 'el-icon-close' : 'el-icon-menu'" />
      </div>
    </el-popover>
  </div>
</template>

<script>
import { Popover } from 'element-ui'
import debounce from "lodash.debounce";
import cloneDeep from "lodash.clonedeep";
import { getTextFromHtmlStr } from '~/utils/htmlStringHandler'
import {DeviceEnum} from "~/enums/deviceEnum";
import {sleep} from "~/utils";
import {scrollTo, on, off} from "~/utils/dom";

export default {
  name: "ModuleNavigationWidget",
  components: {
    [Popover.name]: Popover
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: DeviceEnum.DESKTOP
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      offsetLeft: 0,
      activeIndex: -1,
      debounceScroll: ''
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    menuList() {
      const ignores = ['CarouselWidgetModel', 'RechargeWidgetModel']
      return this.site.widgetList.map(item => {
        const { icon, isDefault, id } = item.getMenuInfo()
        const title = getTextFromHtmlStr(item.title)
        return {
          title,
          icon,
          isDefault,
          id,
          name: item.name,
          visible: (!item.isDefault && !ignores.includes(item.name)) && (item.titleVisible && title)
        }
      }).filter(item => item.visible)
    },
    containerClass() {
      return this.editing ? '.edit-content__center--wrapper' : 'site-body'
    }
  },
  watch: {
    device(n) {
      if (n === DeviceEnum.MOBILE) {
        this.getStyle()
      }
    }
  },
  beforeDestroy() {
    off(document.getElementById('site-body'), 'scroll', this.debounceScroll)
  },
  mounted() {
    this.debounceScroll = debounce(this.checkScroll, 300)
    on(document.getElementById('site-body'), 'scroll', this.debounceScroll)
  },
  methods: {
    checkScroll() {
      const scrollTop = document.getElementById('site-body').scrollTop
      const menuList = cloneDeep(this.menuList)
      menuList.forEach(item => {
        const newItem = {...item}
        const dom = document.getElementById(newItem.id)
        item.offsetTop = dom.offsetTop - (dom.offsetHeight / 2)
      })
      for (let i = 0; i < menuList.length; i++) {
        const item = menuList[i]
        if (scrollTop >= item.offsetTop) {
          this.activeIndex = i;
        }
      }
      if (scrollTop === 0) {
        this.activeIndex = 0
      }
    },
    async getStyle() {
      if (!this.editing) return
      await sleep(500)
      const $dom = document.querySelector('.edit-content__center')
      this.offsetLeft = $dom.offsetLeft + 'px'
    },
    handleToModule(item, index) {
      scrollTo(item.id, this.containerClass)
      this.activeIndex = index;
    },
    handleToTop() {
      const $container = this.editing ? document.querySelector(this.containerClass) : document.getElementById(this.containerClass)
      $container.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleShowMenu() {
      this.visible = !this.visible
    }
  }
}
</script>

<style lang="less">
.module-navigation {
  position: fixed;
  top: 50%;
  right: -20px;
  transform: translate(-50%, -50%);
  &.is-edit {
    left: unset;
    right: 220px;
  }
  &.is-edit.is-mobile {
    right: calc(var(--offset-left) - 45px);
  }
  z-index: 1000;
  &__btn {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    cursor: pointer;
  }
  &__content {
    border-radius: 6px;
    overflow: hidden;
    .menu-list {
      padding: 10px 0 0 0;
      .item {
        display: flex;
        align-items: center;
        height: 32px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 10px;
        &.active {
          background-color: @primary-1;
          color: white !important;
        }
        &:hover {
          color: @primary-1;
        }
        &-title {
          max-width: 100px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  &__popover {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}
</style>
