<template>
  <bg-style :bg="model.background" :style="getTextColor(model.moduleColor, true)" class="lottery-widget p-5" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }">
    <rich-text v-if="model.titleVisible" v-model="model.title" :editing="editing" :class="$store.getters.isDesktop && 'xl:text-[40px]'" class="text-[20px] w-full relative title mt-8" theme="snow" :disabled="!editing" />
    <rich-text v-if="model.subtitleVisible" v-model="model.subtitle" :editing="editing" class="mt-3 relative text-[20px]" theme="snow" :disabled="!editing" />
    <div class="w-full flex items-center mx-auto max-w-[800px] mt-8 justify-between relative">
      <div class="text-14">
        <span>Mine:</span>
        <span class="text-success-6">{{ currentValue }}</span> / <span>{{ totalValue }}</span>
      </div>
      <div class="text-14 flex items-center">
        <i class="el-icon-timer text-18" />
        <span class="ml-2">{{ time }}</span>
      </div>
    </div>
    <div class="lottery-widget__content mt-5">
      <bg-style class="lottery-widget__pan" :bg="model.panBackground">
        <div class="inner-border" :style="{ borderColor: model.circleBackground.backgroundColor }" />
        <bg-style class="inner-circle" :bg="model.blockBackground" />
        <bg-style :bg="model.giftBackground" class="inner-block" :style="panStyle">
          <template v-if="list.length && list[0].angle">
            <div v-for="(item, index) in list" :key="`${index}-${item.id}`" class="bg-item" :style="item.bgStyle">
              <div class="circle-flag" :style="{ transform: `skewY(${item.bgStyle.skewYDeg}deg)` }" />
            </div>
            <div v-for="(item) in list" :key="item.id" class="item" :style="item.style">
              <div class="chance text-12">
                <span>{{ item.weight }}%</span>
              </div>
              <div class="pic flex-col flex items-center justify-center">
                <img :src="item.gift_goods_logo" alt="">
              </div>
              <div class="flex items-center justify-center text-12 gap-1 number">
                <i class="el-icon-close font-bold" />
                <span>{{ item.num }}</span>
              </div>
            </div>
          </template>
        </bg-style>
      </bg-style>
      <div class="pointer flex items-center justify-center" :style="pointerStyle" @click="lottery">
        <div class="pointer-bg flex items-center justify-center">
          <bg-style class="go" :bg="model.axleBackground" :radius="100" :style="{ border: `2px solid ${model.axleBorderColor}` }">
            <span class="relative" :style="{'-webkit-text-stroke': `2px ${model.goBorderColor}`, ...getTextColor(model.goColor, true)}">GO</span>
          </bg-style>
        </div>
      </div>
    </div>
    <div class="text-center relative mt-8 flex items-center justify-center">
      <span class="ml-[90px]" :style="getTextColor(model.moduleColor, true)">{{ $t('siteBuild.lotteryWidget.count') }}：{{ remainTimes }}</span>
      <div>
        <im-icon class="text-20 mx-5 cursor-pointer" icon="icon-help" @click.native="handleShowRule"/>
        <i class="el-icon-tickets text-20 cursor-pointer" @click="handleShowHistory" />
      </div>
    </div>
<!--    <div class="lottery-widget__handle">-->
<!--      <div class="draw-progress">-->
<!--        <div class="title text-20 text-center" :style="getTextColor(model.moduleColor, true)">Progress towards winning the chance to draw：</div>-->
<!--        <bg-style class="progress" :bg="model.progressBackground">-->
<!--          <bg-style class="inner" :bg="model.innerBackground" />-->
<!--        </bg-style>-->
<!--        <div class="w-full flex justify-between mt-3 text-14">-->
<!--          <div class="text-success-6">30</div>-->
<!--          <div>100</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="lottery-widget__info">-->
<!--      <div class="item">-->
<!--        <span>{{ $t('siteBuild.lotteryWidget.rule') }}</span>-->
<!--        <i class="el-icon-arrow-right" />-->
<!--      </div>-->
<!--      <div class="item" @click="handleShowHistory">-->
<!--        <span>{{ $t('siteBuild.lotteryWidget.drawHistory') }}</span>-->
<!--        <i class="el-icon-arrow-right" />-->
<!--      </div>-->
<!--    </div>-->
    <div class="lottery-widget__gifts mt-8 relative">
      <div class="mb-5">
        <span class="text-18 text-bold">{{ $t('siteBuild.lotteryWidget.consecutive') }}:</span>
      </div>
      <div class="gift-list">
        <div v-for="(item, index) in giftList" :key="index" class="item flex items-center justify-center flex-col" :class="{ 'active': item.active }">
          <div class="pic">
            <img :src="item.gift_goods_logo" class="w-full relative z-10 cursor-pointer" alt="goods" @click="handleGetPrize(item)">
            <bg-style v-if="(index === 1 && editing) || item.get_status === 2" class="w-full h-full get" :bg="model.getBackground" />
            <bg-style v-if="(index === 0 && editing) || item.get_status === 1" class="w-full h-full received z-20" :bg="model.receivedBackground" />
          </div>
          <div class="index mt-10">{{ item.times_no }}</div>
          <bg-style class="line" :bg="item.active ? model.innerBackground : model.progressBackground" />
        </div>
      </div>
    </div>
    <history-dialog ref="history" :model="model" />
    <rule-dialog ref="rule" :model="model" />
    <el-dialog class="gift-dialog" :width="$store.getters.isMobile ? '100%' : '520px'" :visible.sync="visible">
      <div class="gift-dialog__content">
        <div class="title">{{ isHit ? model.successTitle : model.fallTitle }}</div>
        <div class="flex items-center flex-col justify-center mt-8">
          <img class="w-[110px] h-[110px]" :src="currentPrize.gift_goods_logo" alt="">
          <div v-if="isHit" class="text-20 font-bold">
            <i class="el-icon-close text-black" />
            <span class="text-black">{{ currentPrize.num }}</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="text-center">
        <i class="el-icon-circle-close text-white text-28 cursor-pointer" @click="handleCloseDialog"/>
      </div>
    </el-dialog>
<!--    <div style="margin: 0 auto;" class="pt-10 w-fit">-->
<!--      <div class="flex items-center">-->
<!--        <span>指定奖品：</span>-->
<!--        <el-input v-model="prize" class="w-[200px]" placeholder="请输入您想要的奖品名称" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div style="margin: 0 auto;" class="pt-10 w-fit">-->
<!--      <div class="flex items-center">-->
<!--        <span>奖品个数：</span>-->
<!--        <el-input-number v-model="number" :min="3" :max="12" class="w-[200px]" placeholder="请输入奖品个数" />-->
<!--        <site-button class="ml-5" @click="generatePrizes">生成</site-button>-->
<!--      </div>-->
<!--    </div>-->
  </bg-style>
</template>

<script>
import { Dialog, Message } from "element-ui";
import {mapState} from "vuex";
import isNil from "lodash.isnil";
import moment from "moment/moment";
import RuleDialog from './rule.vue'
import HistoryDialog from './history.vue'
import RichText from "~/components/richText/index.vue";
import {formatZoneTime, getTextColor} from "~/utils";
import {DeviceEnum} from "~/enums/deviceEnum";
import ImIcon from "~/components/common/ImIcon.vue";
import Bus from "~/site/model/bus";

export default {
  name: 'LotteryWidget',
  components: {
    ImIcon,
    RichText,
    HistoryDialog,
    RuleDialog,
    [Dialog.name]: Dialog,
  },
  props: {
    device: {
      type: String,
      default: DeviceEnum.MOBILE
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      DeviceEnum,
      stopTime: 0,
      visible: false,
      currentValue: 0,
      totalValue: 0,
      time: 0,
      giftList: [],
      prize: '',
      rotateDeg: 0,
      list: [],
      remainTimes: 0,
      isHit: false,
      panStyle: {
        transform: 'rotate(0)'
      },
      number: 12,
      rotating: false
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
    bizId() {
      return this.model.bizId
    },
    currentPrize() {
      return this.list.find(item => item.gift_no === this.prize) || {}
    },
    pointerStyle() {
      const model = this.model
      return {
        '--arrow-color': model.arrowBackground.backgroundColorShow ? model.arrowBackground.backgroundColor : 'unset',
        background: model.drawBackground.backgroundColor,
        border: `2px solid ${model.drawBorderColor}`
      }
    }
  },
  watch: {
    bizId(n, o) {
      if (n !== o) {
        this.init()
      }
    }
  },
  created() {
  },
  mounted() {
    Bus.$on('reloadActivity', () => {
      this.init()
    })
    if (this.editing) {
      this.init()
    }
  },
  methods: {
    checkStatus() {
      let result = true
      if (isNil(this.userInfo?.id)) {
        result = false
      }
      return result
    },
    async handleGetPrize(item) {
      if (this.editing) return
      if (!this.checkStatus()) {
        return this.showLogin()
      }
      const params = {
        activity_id: this.model.bizId,
        times_no: item.times_no
      }
      const [err] = await this.$utils.to(this.$api.activity.getPrize(params))
      if (!err) {
        Message.success('Success')
        await this.init()
      } else {
        Message.error(err)
      }
    },
    showLogin() {
      this.$store.commit('user/SET_LOGINSHOW', !this.editing)
      this.$store.commit('user/SET_SITE_BIND_USER', true)
    },
    async getLotteryDetail() {
      if (!this.checkStatus()) return
      const params = {
        activity_id: this.model.bizId
      }
      const [err, res] = await this.$utils.to(this.$api.activity.queryLotteryDetail(params))
      if (!err) {
        this.giftList = res.count_lottery_list.map(item => {
          const newItem = {...item}
          newItem.active = [1, 2].includes(item.get_status)
          return newItem
        })
        this.remainTimes = res.remain_lottery_times
        this.currentValue = res.current_lottery_amount
        this.totalValue = res.target_lottery_amount
      }
    },
    getTextColor,
    setTime() {
      const now = moment().valueOf()
      const end = formatZoneTime(this.stopTime * 1000).value
      const leftTime = end - now;
      let d = 0; let h = 0; let m = 0; let s = 0;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        m = Math.floor(leftTime / 1000 / 60 % 60);
        s = Math.floor(leftTime / 1000 % 60);
      }
      this.time = `${d > 0 ? `${d}d:` : ''}${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`
      if (leftTime > 0) setTimeout(this.setTime, 1000);
    },
    async getActivityDetail() {
      const params = {
        biz_id: this.bizId
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList(params))
      if (!err) {
        const data = res?.list || []
        if (!data.length) return
        const rule = JSON.parse(data[0].activity_rule)
        this.stopTime = data[0].stop_time
        this.setTime()
        this.list = rule.lottery_list.map(item => {
          const newItem = {...item}
          newItem.bgStyle = {}
          newItem.style = {}
          newItem.angle = 0
          return newItem
        })
        if (rule.lottery_type === 2) {
          this.totalValue = rule.fiat_currency.amount
        } else {
          this.totalValue = rule.virtual_currency.num
        }
        this.giftList = rule.count_lottery_list
      }
    },
    async init() {
      await this.getActivityDetail()
      await this.getLotteryDetail()
      this.generatePrizes()
    },
    handleCloseDialog() {
      this.visible = false
    },
    handleShowRule() {
      if (this.editing) return
      this.$refs.rule.init()
    },
    handleShowHistory() {
      if (!this.checkStatus()) {
        return this.showLogin()
      }
      if (this.editing) return
      this.$refs.history.init()
    },
    async lottery() {
      if (!this.checkStatus()) {
        return this.showLogin()
      }
      if (this.rotating) return
      const params = {
        activity_id: this.model.bizId
      }
      const [err, res] = await this.$utils.to(this.$api.activity.lotteryDraw(params))
      if (!err) {
        this.prize = res.gift_no
      } else {
        Message.error(err)
      }
      if (err) return
      const CIRCLE_ANGLE = 360
      const config = {
        duration: 5000,
        circle: 8
      }
      this.rotating = true
      const prize = this.list.find(item => item.gift_no === this.prize)
      this.rotateDeg = this.rotateDeg + config.circle * CIRCLE_ANGLE + prize.angle - (this.rotateDeg % CIRCLE_ANGLE)
      this.panStyle.transform = `rotate(${this.rotateDeg}deg)`
      const timer = setTimeout(() => {
        this.rotating = false
        this.showResult()
        clearTimeout(timer)
      }, config.duration + 500)
    },
    showResult() {
      const current = this.list.find(item => item.gift_no === this.prize)
      this.isHit =  !['-1', -1].includes(current.gift_goods_id)
      this.visible = true
      this.getLotteryDetail()
    },
    generatePrizes() {
      const CIRCLE_ANGLE = 360
      const length = this.list.length
      const average = CIRCLE_ANGLE / length
      const half = average / 2
      const rightBig = length === 2 ? '50' : '0'
      const heightBig = length <= 3 ? '100': '50'
      const topBig = length === 3 ? '-50' : '0'
      const skewMain = length <= 2 ? 0 : -(length - 4) * 90 / length
      this.list.forEach((item, i) => {
        const angle = -(i * average + half)
        const bigAge = length > 2 ? i * 360 / length : '0'
        item.angle = angle
        item.bgStyle = {
          transform: `rotate(${bigAge}deg) skewY(${skewMain}deg)`,
          right: `${rightBig * i}%`,
          height: `${heightBig}%`,
          top: `${topBig}%`,
          width: `${length === 1 ? 100 : 50}%`,
          skewYDeg: Math.abs(skewMain)
        }
        item.style = {
          transform: `rotate(${-angle}deg)`,
          width: `${100 / length * 2}%`,
          marginLeft: `-${100 / length}%`,
          fontSize: '20px'
        }
      })
    }
  }
}
</script>

<style lang="less">
.gift-dialog {
  .el-dialog {
    box-shadow: none;
    background-color: transparent;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    background-color: white;
  }
  &__content {
    .title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: black;
    }
  }
}
.lottery-widget {
  &.is-mobile {
    .lottery-widget__gifts {
      .gift-list {
        .item {
          .pic {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .lottery-widget__handle {
      .draw-progress {
        padding: 10px;
        .title {
          font-size: 14px !important;
        }
      }
    }
    .pointer {
      width: 84px;
      height: 84px;
      .pointer-bg {
        width: 62px;
        height: 62px;
        .go {
          width: 48px;
          height: 48px;
          span {
            font-size: 30px;
          }
        }
      }
      &:after {
        content: '';
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 48px solid var(--arrow-color);
        top: -33px;
      }
    }
    .lottery-widget__pan {
      width: 330px;
      height: 330px;
      .inner-circle {
        width: 310px;
        height: 310px;
      }
      .inner-block {
        width: 290px;
        height: 290px;
        .item {
          .chance {
            position: relative;
            top: -14px;
          }
          .pic {
            margin-top: -5px;
            img {
              width: 30px;
              height: 30px;
            }
          }
          .number {
            margin-top: 0;
            transform: scale(0.8);
          }
        }
      }
      .inner-border {
        width: 290px;
        height: 290px;
        border: 20px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .pointer {
      width: 122px;
      height: 122px;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      z-index: 2;
      .pointer-bg {
        width: 100px;
        height: 100px;
        background-color: var(--arrow-color);
        border-radius: 100%;
        .go {
          width: 86px;
          height: 86px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          span {
            font-size: 45px;
            font-weight: bold;
            color: #F73600;
            font-family: monospace;
          }
        }
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 54px solid var(--arrow-color);
        position: absolute;
        top: -40px;
      }
    }
  }
  &__handle {
    max-width: 1220px;
    margin: 30px auto;
    position: relative;
    .draw-progress {
      max-width: 660px;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.1);
      margin: 0 auto;
      .progress {
        background-color: white;
        height: 16px;
        position: relative;
        margin-top: 16px;
        .inner {
          height: 100%;
          width: 30%;
          background-color: #00C970;
        }
      }
    }
  }
  &__info {
    max-width: 1220px;
    margin: 30px auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .item {
      background-color: rgba(255, 255, 255, 0.2);
      padding: 8px 12px;
      border-radius: 32px;
      cursor: pointer;
    }
  }
  &__gifts {
    max-width: 1220px;
    margin: 0 auto;
    .gift-list {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: nowrap;
      overflow: auto;
      .item {
        width: 20%;
        flex-shrink: 0;
        position: relative;
        .received, .get {
          position: absolute !important;
          top: 0;
          left: 0;
        }
        .pic {
          width: 66px;
          position: relative;
          height: 66px;
        }
        .line {
          position: absolute;
          bottom: 34px;
          width: 100%;
          height: 3px;
          left: 0;
        }
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 100%;
          position: absolute;
          bottom: 31px;
          background-color: white;
        }
      }
    }
  }
  &__pan {
    width: 496px;
    height: 496px;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .inner-border {
      width: 416px;
      height: 416px;
      border: 30px solid rgba(255, 255, 255, 0.2);
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      z-index: 1;
    }
    .inner-circle {
      width: 456px;
      height: 456px;
      border-radius: 100%;
      background-color: white;
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }
    .inner-block {
      //background-color: white;
      width: 416px;
      height: 416px;
      border-radius: 100%;
      position: relative;
      overflow: hidden;
      transition: transform 5s ease-in-out;
      .bg-item {
        position:absolute;
        top: 0;
        right: 0;
        transform-origin: 0 100%;
        border: 2px solid white;
        border-right:0;
        border-top:0;
        box-sizing: border-box;
        .circle-flag {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: white;
          z-index: 10;
          position: relative;
          left: -12px;
          top: -10px;
        }
      }
      .item {
        position: absolute;
        left: 50%;
        height: 50%;
        padding-top:15px;
        box-sizing: border-box;
        text-align: center;
        transform-origin: 50% 100%;
        .chance {
          position: relative;
          top: -10px;
        }
        .pic {
          margin-top: 8px;
          img {
            width: 44px;
            height: 44px;
          }
        }
        .number {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
