<template>
  <div class="lottery-widget-edit">
    <menu-item label="活动">
      <im-select v-model="model.bizId" class="w-full" size="small" placeholder="活动名称" :options="activityList" />
    </menu-item>
    <menu-item label="背景设置">
      <menu-background-image :target="model.background" target-key="background" />
      <menu-fill class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" label="描述" />
    </menu-item>
    <menu-item label="转盘外圆">
      <menu-background-image :target="model.panBackground" target-key="panBackground" />
      <menu-fill class="mt-2" :target="model.panBackground" target-key="panBackground" />
    </menu-item>
    <menu-item label="线框圆">
      <menu-fill class="mt-2" :target="model.blockBackground" target-key="blockBackground" />
    </menu-item>
    <menu-item label="概率圆">
      <menu-fill class="mt-2" :target="model.circleBackground" target-key="circleBackground" />
    </menu-item>
    <menu-item label="奖品圆">
      <menu-fill class="mt-2" :target="model.giftBackground" target-key="giftBackground" />
    </menu-item>
    <menu-item label="转轴外圆填充">
      <menu-fill class="mt-2" :target="model.drawBackground" target-key="drawBackground" />
    </menu-item>
    <menu-item label="转轴外圆描边">
      <menu-fill class="mt-2" :target="model" color-key="drawBorderColor" />
    </menu-item>
    <menu-item label="箭头">
      <menu-fill class="mt-2" :target="model.arrowBackground" target-key="arrowBackground" />
    </menu-item>
    <menu-item label="转轴按钮填充">
      <menu-fill class="mt-2" :target="model.axleBackground" target-key="axleBackground" />
    </menu-item>
    <menu-item label="转轴按钮描边">
      <menu-fill class="mt-2" :target="model" color-key="axleBorderColor" />
    </menu-item>
    <menu-item label="按钮文字填充">
      <menu-fill class="mt-2" :target="model" color-key="goColor" />
    </menu-item>
    <menu-item label="按钮文字描边">
      <menu-fill class="mt-2" :target="model" color-key="goBorderColor" />
    </menu-item>
<!--    <menu-item label="文字颜色">-->
<!--      <menu-fill class="mt-2" :target="model" color-key="textColor" eye-key="textColorShow" />-->
<!--    </menu-item>-->
    <menu-item label="进度条背景">
      <menu-fill class="mt-2" :target="model.progressBackground" target-key="progressBackground" />
    </menu-item>
    <menu-item label="进度条填充">
      <menu-fill class="mt-2" :target="model.innerBackground" target-key="innerBackground" />
    </menu-item>
    <menu-item label="已领取标识">
      <menu-background-image :target="model.receivedBackground" target-key="receivedBackground" />
      <menu-fill class="mt-2" :target="model.receivedBackground" target-key="receivedBackground" />
    </menu-item>
    <menu-item label="可领取标识">
      <menu-background-image :target="model.getBackground" target-key="getBackground" />
      <menu-fill class="mt-2" :target="model.getBackground" target-key="getBackground" />
    </menu-item>
    <menu-item label="模块文字颜色">
      <menu-fill class="mt-2" :target="model" color-key="moduleColor" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapState} from "vuex";
import MenuItem from "~/components/menu/menuItem.vue"
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"
import MenuFill from "~/components/menu/menuFill.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue"
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import ImSelect from "~/components/common/ImSelect.vue";

export default {
  name: 'LotteryWidgetEdit',
  components: {
    ImSelect,
    RemoveBtn,
    MenuSwitch,
    MenuItem,
    MenuBackgroundImage,
    MenuFill
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      activityList: []
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getActivityList()
    },
    async getActivityList() {
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      const params = {
        now_time: now_stem,
        activity_type: 8,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || '',
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList(params))
      if (!err) {
        const data = res?.list || []
        this.activityList = data.map(item => {
          return {
            label: item.name,
            value: item.biz_id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
